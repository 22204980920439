import React from "react";
import { twMerge } from "tailwind-merge";
function SubSeparator({
  label,
  className,
  labelClassName,
  separatorClassName,
  marginBottom = "mb-6"
}: {
  label?: string;
  className?: string;
  labelClassName?: string;
  separatorClassName?: string;
  marginBottom?: string;
}) {
  return <div className={twMerge(`flex mt-12 ${marginBottom} gap-6 items-center justify-center w-full`, className)} data-sentry-component="SubSeparator" data-sentry-source-file="sub-separator.tsx">
      {label && <p className={twMerge("text-xl font-bold text-nowrap", labelClassName)}>
          {label}
        </p>}
      <div className={twMerge("w-full h-0.5 bg-grey", separatorClassName)}></div>
    </div>;
}
export default SubSeparator;